import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
    width: 100%;
    font-size: 16px;
    line-height: 1.3;
    font-family: "Grotesk", serif;
  }

  body {
    padding: 0;
    margin: 0;
    background: ${props => props.theme.primaryColor};
  }

  a {
    text-decoration: none;
    color: ${props => props.theme.accentColor};
  }

  ul {
    margin: 0 auto;
    list-style-type: disc;
  }

  * {
    box-sizing: border-box;
  }
`
